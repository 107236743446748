<template>
  <div class="realName">
    <div class="content">
      <div class="real-name">
        <span>姓名：</span>
        <input
          type="text"
          v-model="uname"
          placeholder="请输入姓名"
          autocomplete="off"
          :readonly="isAuth"
        />
      </div>
      <div class="auth-type">
        <span class="type-title">认证方式：</span>
        <div class="types">
          <div class="type-item">
            <input
              name="auth_type"
              @change="chooseType"
              type="radio"
              v-model="card"
              value="1"
              :disabled="isAuth"
            />
            <span>身份证</span>
          </div>
          <div class="type-item">
            <input
              name="auth_type"
              @change="chooseType"
              type="radio"
              v-model="card"
              value="2"
              :disabled="isAuth"
            />
            <span>护照</span>
          </div>
        </div>
      </div>

      <div class="auth-content">
        <!-- 身份证 -->
        <div class="ID-card" v-show="authentication">
          <div class="photo">
            <h4>身份证证件：</h4>
            <div class="files">
              <van-uploader
                :deletable="!isAuth"
                :disabled="isAuth"
                v-model="picA"
                :after-read="afterRead"
                :max-count="1"
                :upload-icon="picA_bg"
              />
              <van-uploader
                :deletable="!isAuth"
                :disabled="isAuth"
                v-model="picB"
                :after-read="afterRead2"
                :max-count="1"
                :upload-icon="picB_bg"
              />
            </div>
          </div>
          <div class="safe">
            <img src="@assets/img/safe.png" alt="" />
            <span>汇成国际保障你的信息安全，信息仅用于身份验证</span>
          </div>
          <div class="btn-submit">
            <van-button
              :disabled="isAuth"
              round
              block
              type="info"
              native-type="button"
              @click="submit"
              >提交</van-button
            >
          </div>
        </div>
        <!-- 护照认证 -->
        <div class="passport" v-show="!authentication">
          <div class="photo">
            <h4>护照证件：</h4>
            <div class="files">
              <van-uploader
                :deletable="!isAuth"
                :disabled="isAuth"
                class="hz1"
                v-model="picA2"
                :after-read="afterRead3"
                :max-count="1"
                :upload-icon="picA_bg2"
              />
              <van-uploader
                :deletable="!isAuth"
                :disabled="isAuth"
                class="hz2"
                v-model="picB2"
                :after-read="afterRead4"
                :max-count="1"
                :upload-icon="picB_bg2"
              />
            </div>
          </div>
          <div class="safe">
            <img src="@assets/img/safe.png" alt="" />
            <span>汇成国际保障你的信息安全，信息仅用于身份验证</span>
          </div>
          <div class="btn-submit">
            <van-button
              :disabled="isAuth"
              round
              block
              type="info"
              native-type="button"
              @click="submit2"
              >提交</van-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getAuth, auth } from "@network/mine";
import { imgUpload } from "@network/setting";
export default {
  data() {
    return {
      net: BASE_URL,
      uname: "",
      card: "1",
      picA: [],
      picB: [],
      picA2: [],
      picB2: [],
      picA_bg: require("../../../assets/img/c1-bg.png"),
      picB_bg: require("../../../assets/img/c2-bg.png"),
      picA_bg2: require("../../../assets/img/p1-bg.png"),
      picB_bg2: require("../../../assets/img/p2-bg.png"),

      imgPathA: "", //身份证正
      imgPathB: "", //身份证反
      imgPathA2: "", //护照正
      imgPathB2: "", //护照反
      isAuth: false,
    };
  },
  created() {
    this.getAuth();
  },

  methods: {
    chooseType() {
      console.log(this.card);
    },
    getAuth() {
      getAuth().then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({message:"请重新登录",offset: 300});
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取认证信息失败",
            offset: 300
          });
          return false;
        } else {
          if (res.data.auth_name == "") {
            return false;
          }
          if (res.data.auth_type == 1 || res.data.auth_type == 2) {
            this.card = JSON.stringify(res.data.auth_type);
          }
          if (res.data.auth == 1 || res.data.auth == 2) {
            this.isAuth = true;
            this.uname = res.data.auth_name;
            console.log(this.net + res.data.auth_pic_a);
            if (res.data.auth_type == 1) {
              this.picA.push({ url: this.net + res.data.auth_pic_a });
              this.picB.push({ url: this.net + res.data.auth_pic_b });
            } else if (res.data.auth_type == 2) {
              this.picA2.push({ url: this.net + res.data.auth_pic_a });
              this.picB2.push({ url: this.net + res.data.auth_pic_b });
            }
          } else {
            this.isAuth = false;
            this.uname = "";
          }
        }
      });
    },
    //身份证
    submit() {
      if (this.uname == "") {
        this.$message({
          message: "请输入姓名",
          offset: 300
        });
        return false;
      } else if (this.imgPathA == "") {
        this.$message.error({
          message: "请上传身份证正面",
          offset: 300
        });
        return false;
      } else if (this.imgPathB == "") {
        this.$message.error({
          message: "请上传身份证反面",
          offset: 300
        });
        return false;
      }
      auth(this.uname, this.imgPathA, this.imgPathB, this.card).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: res.msg,
            offset: 300
          });
          return false;
        } else {
          this.$message.success({
            message: res.msg,
            offset: 300
          });
          this.$router.replace("/vipCenter/profile");
        }
      });
    },
    //护照
    submit2() {
      if (this.uname == "") {
        this.$message.error({
          message: "请输入姓名",
          offset: 300
        });
        return false;
      } else if (this.imgPathA2 == "") {
        this.$message.error({
          message: "请上传身份证正面",
          offset: 300
        });
        return false;
      } else if (this.imgPathB2 == "") {
        this.$message.error({
          message: "请上传身份证反面",
          offset: 300
        });
        return false;
      }
      auth(this.uname, this.imgPathA2, this.imgPathB2, this.card).then(
        (res) => {
          console.log(res);
          if (res.code != 0) {
            this.$message.error({
              message: res.msg,
              offset: 300
            });
            return false;
          } else {
            this.$message.success({
              message: res.msg,
              offset: 300
            });
            this.$router.replace("/vipCenter/profile");
          }
        }
      );
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      imgUpload(file.file).then((res) => {
        console.log(res);
        this.$message({
          message: res.msg,
          offset: 300
        });
        if (res.code != 0) {
          return false;
        } else {
          this.imgPathA = res.data.path;
          console.log(this.imgPathA);
        }
      });
    },
    afterRead2(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      imgUpload(file.file).then((res) => {
        console.log(res);
        this.$message({
          message: res.msg,
          offset: 300
        });
        if (res.code != 0) {
          return false;
        } else {
          this.imgPathB = res.data.path;
          console.log(this.imgPathB);
        }
      });
    },
    afterRead3(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      imgUpload(file.file).then((res) => {
        console.log(res);
        this.$message({
          message: res.msg,
          offset: 300
        });
        if (res.code != 0) {
          return false;
        } else {
          this.imgPathA2 = res.data.path;
          console.log(this.imgPathA2);
        }
      });
    },
    afterRead4(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      imgUpload(file.file).then((res) => {
        console.log(res);
        this.$message({
          message: res.msg,
          offset: 300
        });
        if (res.code != 0) {
          return false;
        } else {
          this.imgPathB2 = res.data.path;
          console.log(this.imgPathB2);
        }
      });
    },
  },
  computed: {
    //认证方式
    authentication() {
      return this.card == "1" ? true : false;
    },
  },
};
</script>

<style lang='less' scoped>
@import "@assets/css/vipCenter/ChildComps/realNameVerification.less";
</style>